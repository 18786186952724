<template>
    <div class="news_box">
         <!-- 加载动画 -->
        <transition name="fade">
            <loading v-if="isLoading"></loading>
        </transition>
        <Header></Header>
        <div class="container">
            <div class="new_container clearfix">
                <!-- 新闻列表 -->
                <div class="news_list f_left">
                    <div class="news_list_content">
                        <!-- 搜索 -->
                        <!-- <div class="search_box">
                            <input type="text" :placeholder="placeholder" class="search_input" v-model="keyword" ref="searchInput" @keyup.enter="searchNews" @blur="inputBlur">
                            <i class="search_icon" @click="searchNews"></i>
                            <ul :class="['search_keywords', {'anim': animate == true}]" ref="con1" v-show="isInputChange == false" @click="inputFocus">
                                <li class="keywords" v-for="(word, index) in keywordsArr" :key="index">{{word}}</li>
                            </ul>
                        </div> -->
                        <div class="news_type">
                            <!-- <h2 :class="['news_type_title', newsType]">{{newsTypeName}}</h2> -->
                            <ul class="news_type_list clearfix" v-if="newsTypeList[0]">
                                <li
                                  v-for="(item, index) in newsTypeList[0].child"
                                  :key="index"
                                  :class="['type_name f_left', {'active': newsIndex == index }]"
                                  @click="changeNewsType(item, index)"
                                >{{item.title}}</li>
                            </ul>
                        </div>
                        <!-- 有数据 -->
                        <ul class="news_detail_list" v-if="hasNews == true">
                            <li
                              v-for="(news, index) in newsLists"
                              :key="index"
                              :class="['news_li', news.liClass, {'other_li': !news.isVideo}]"
                              @click="seeNewsDetail(news)"
                            >
                                <!-- 列表渲染  是否为视频 -->
                                <div class="news_detail_box" v-if="news.isVideo">
                                    <h3 class="news_title text_twoLine" v-html="news.title"></h3>
                                    <video :src="news.image" class="video_box" controls autoplay></video>
                                </div>
                                <div class="news_detail_box" v-else>
                                    <!-- 是否为多张图 -->
                                    <template v-if="news.image.length < 3">
                                        <!-- <span class="news_image f_left" :style="{backgroundImage: 'url(' + news.image[0] + ')'}"></span> -->
                                        <img :src="news.image[0]|$DateFormatimg" class="news_image f_left" alt="">
                                        <h3 class="news_title f_left text_twoLine"  v-html="news.title"></h3>
                                    </template>
                                    <template v-else>
                                        <h3 class="news_title text_twoLine" v-html="news.title"></h3>
                                        <ul class="image_list clearfix">
                                            <li
                                            class="image f_left"
                                            v-for="(imageUrl, imageIndex) in news.image"
                                            :key="imageIndex"
                                            :style="{backgroundImage: 'url(' + imageUrl + ')'}"
                                            ></li>
                                        </ul>
                                    </template>
                                </div>
                                <p class="views_box">
                                    <i class="views_icon"></i>
                                    <span class="views_num">{{news.hits}}</span>
                                </p>
                            </li>
                        </ul>
                        <!-- 无数据 -->
                        <div class="no_result_box" v-if="hasNews == false">
                            <div class="result_content">
                                <i class="no_result_icon"></i>
                                <span class="no_result_text">当前没有符合您要求的新闻哦</span>
                            </div>
                        </div>
                    </div>
                    <div class="page_container" v-if="hasNews == true">
                        <m-pagination :currentpage="currentPage" :total="totalPage" @current-change="currentChange"></m-pagination>
                    </div>
                </div>
                <!-- 推荐新闻 -->
                <div class="f_left news_index_recommend">
                    <m_recommend></m_recommend>
                </div>
            </div>
        </div>
        <Footer :footerBgColor="footerBgColor"></Footer>
    </div>
</template>
<script>
import { newsInterface } from '@/config/request'
import m_recommend from '../recommend/recommend'
export default {
    name: 'news',
    components: {
        m_recommend
    },
    watch: {
        keyword(newValue) {
            clearInterval(this.timer)
            this.isInputChange = true
        }
    },
    data(){
        return {
            footerBgColor: '#FBFBFB', // 底部背景颜色
            placeholder: '', // 搜索框默认文字
            newsTypeList: [], // 新闻tab栏数据
            tabClassName: new Map([
                ['xwzx', ['information']],
                ['mlszsk', ['classroom']],
                ['zxzb', ['equipment']],
                ['pzgs', ['journalism']],
                ['xshd', ['activity']],
                ['default', ['default']],
            ]),
            newsTypeName: '', // tab栏名称
            newsType: "", // 新闻类型切换
            newsModule: '', // 请求不同新闻类型数据
            newsIndex: 0, // 默认选中的新闻类型
            newsLists: [], // 新闻列表
            currentPage: 1, // 当前页
            totalPage: 0, // 总条数
            keywordsArr: [], // 轮播搜索关键字
            keyword: '', // 关键字
            animate: false, // 是否轮播切换关键字
            timer: null,
            isInputChange: false, // 输入框是否输入内容，停止关键字轮播
            hasNews: true, // 是否有新闻
            isLoading:true
        }
    },
    methods: {
        // 获取新闻tab栏数据
        getNewsTabData() {
            newsInterface.newsTabList({}).then(res => {
                let tabArr = []
                // res.data.forEach(item => {
                //     let className = this.tabClassName.get(item.module) || this.tabClassName.get('default')
                //     let tabClassName = className[0]
                //     tabArr.push({
                //         type: item.name,
                //         module: item.module,
                //         className: tabClassName
                //     })
                // })
                this.newsTypeList = res.data
                console.log( this.newsTypeList);
                this.currentPage = 1// tab栏名称
                this.newsTypeName= this.newsTypeList[0].title
                // this.newsType = this.newsTypeList[0].child.title // 新闻类型切换
                this.newsModule = this.newsTypeList[0].child[0].id// 请求不同新闻类型数据
                this.getData()
            })
        },
        // 获取新闻列表数据
        getData() {
            this.isLoading = true;
            newsInterface.newsListse({
                mid: this.newsModule,
                rows: this.currentPage,
                size:10
            }).then(res => {
                if(res.code == 200) {
                    this.isLoading = false;
                    this.totalPage = res.total
                    let newsArr = []
                    res.data.forEach(item => {
                        // 图片和视频处理
                        let imageArr = []
                        let liClass = ""
                        // let isVideo = !item.image.indexOf('mp4') || !item.image.indexOf('flash')
                        let isVideo = item.coverType == 'VIDEO' ? true : false
                        if(item.coverImage){
                             item.image = item.coverImage.split('|')
                        item.image.forEach(subItem => {
                            if(subItem != "" && subItem.indexOf("http") != -1) {
                                imageArr.push(subItem)
                            }
                        })
                        }
                       
                        if(isVideo) { // 视频
                            imageArr = imageArr[0]
                            liClass = 'news_video'
                        } else { // 图片
                            
                            // 新闻图片3张以下的都按一张图片的页面样式展示
                            if(imageArr.length < 3) {
                                imageArr = imageArr.slice(0, 1) 
                                liClass = "news_one_img"
                            } else {
                                imageArr = imageArr.slice(0, 3)
                                liClass = "news_more_img"
                            }
                        }
                        newsArr.push({
                            id: item.id,
                            hits: item.hits,
                            image: imageArr,
                            title: item.title,
                            isVideo: isVideo,
                            liClass: liClass
                        })
                    })
                    this.newsLists = newsArr
                    console.log(this.newsLists);
                } else {
                    this.isLoading = false;
                    this.totalPage = 0
                    this.currentPage = 1
                    this.newsLists = []
                }
                this.hasNews = this.newsLists.length > 0 ? true : false
            })
        },
        // 获取新闻搜索关键字数据
        getKeyWords() {
            newsInterface.newsKeyWords({
                type: 1,
                pageNo: this.currentPage
            }).then(res => {
                if(res.code == 1) {
                    let keywordArr = []
                    if(res.list.length > 0) {
                        res.list.forEach(item => {
                            keywordArr.push(item.keywords)
                        })
                        this.keywordsArr = keywordArr
                        this.timer = setInterval(this.scroll, 3000)
                    } else {
                        this.placeholder = "请输入您想搜索的内容"
                    }
                    
                }
            })
        },
        // 新闻类型tab栏切换
        changeNewsType(item, index) {
            console.log(item);
            // this.newsTypeName = item.type
            // this.newsType = item.className
            this.newsModule = item.id
            this.newsIndex = index
            this.currentPage = 1
            this.getData()
        },
        // 切换分页
        currentChange(page) {
            this.currentPage = page
            this.getData()
        },
        // 查看新闻详情
        seeNewsDetail(news) {
            // if(!news.isVideo) {
                this.$router.push({
                    path: '/newsDetail',
                    query: {
                        articleId: news.id
                    }
                })
            // }
        },
        // 搜索关键字轮播
        scroll() {
            let con1 = this.$refs.con1;
            con1.style.marginTop = '-45px';
            this.animate = !this.animate;
            setTimeout(() => {
                this.keywordsArr.push(this.keywordsArr[0]);
                this.keywordsArr.shift();
                con1.style.marginTop = '0px';
                this.animate = !this.animate;
            }, 500)
            
        },
        // 搜索新闻
        searchNews() {
            if(!this.isInputChange) {
                this.keyword = this.keywordsArr[0]
            }
            clearInterval(this.timer)
            this.$router.push({
                path: '/newsSearch',
                query: {
                    keyword: this.keyword
                }
            })
        },
        // 点击搜索关键字轮播时，输入框获取焦点
        inputFocus() {
            this.$refs.searchInput.focus()
            this.keyword = this.keywordsArr[0]
            clearInterval(this.timer)
            this.isInputChange = false
        },
        // 输入框失去焦点
        inputBlur() {
            if(!this.keyword) {
                this.isInputChange = false
                this.timer = setInterval(this.scroll, 5000)
            }
        }
    },
    created() {
        this.getNewsTabData()
        // this.getKeyWords()
        
    },
    destroyed() {
      clearInterval(this.timer)
    },
}
</script>

<style scoped>
@import './style/news.css';
</style>
